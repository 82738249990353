/*
 * This file is part of OpenAdvertMap.
 *
 * OpenAdvertMap is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * any later version.
 *
 * OpenAdvertMap is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with OpenAdvertMap.  If not, see <http://www.gnu.org/licenses/>.
 */

let L = require("leaflet");
require("leaflet-hash");
require("leaflet-geosearch");
require("leaflet-geosearch/src/js/l.geosearch.provider.openstreetmap");
require("leaflet.locatecontrol");
require("../../../node_modules/leaflet-overpass-layer/dist/OverPassLayer.bundle.js");

String.prototype.capitalizeFirstLetter = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

/**
 * Main controller manages everything related to main page.
 */
class Main {
//CONSTRUCTOR
	constructor(divId) {
		/*
		 * Map init
		 */
		this.map = L.map(divId).setView([42.5, -3], 3);
		this.map.attributionControl.setPrefix('<a href="http://pavie.info">&copy; Adrien Pavie 2016</a> | <a href="https://framagit.org/PanierAvide/OpenAdvertMap">About</a>');
		let hash = new L.Hash(this.map);

		let http = window.location && window.location.protocol == 'https:' ? 'https:' : 'http:';
		L.tileLayer(http+'//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			name: "OpenStreetMap",
			attribution: "Tiles <a href=\"http://openstreetmap.org/\">OSM</a>",
			maxZoom: 19
		}).addTo(this.map);

		this.mapSearch = new L.Control.GeoSearch({
			provider: new L.GeoSearch.Provider.OpenStreetMap(),
			position: 'topleft',
			showMarker: false,
			retainZoomLevel: false
		}).addTo(this.map);

		L.control.locate().addTo(this.map);

		//Zoom data
		this.lastZoom = this.map.getZoom();
		this.zoomIconSize = { 15: "small", 16: "small", 17: "medium", 18: "medium", 19: "large" };

		//Available icons
		let adIconSize = [32,32];
		let adIconShadowSize = [50,25];
		let adIconAnchor = [16,32];
		let adIconShadowAnchor = [13,23];
		let adIconPopupAnchor = [0,-16];
		let adIcons = {};

		/*
		 * Utility function for creating icons with various sizes
		 */
		let createIcon = function(name, ratio) {
			let applyRatio = (x) => { return x * ratio; };
			return L.icon({
				iconUrl: 'img/'+name+'.png',
				iconSize: adIconSize.map(applyRatio),
				iconAnchor: adIconAnchor.map(applyRatio),
				shadowUrl: (name != "unknown") ? 'img/'+name+'_shadow.png' : undefined,
				shadowSize: (name != "unknown") ? adIconShadowSize.map(applyRatio) : undefined,
				shadowAnchor: (name != "unknown") ? adIconShadowAnchor.map(applyRatio) : undefined,
				popupAnchor: adIconPopupAnchor.map(applyRatio)
			});
		};

		let adIconsList = [ "unknown", "billboard", "board", "board_political", "board_public", "bus_shelter", "column", "flag", "poster_box", "screen", "sign", "totem" ];
		for(let i=0; i < adIconsList.length; i++) {
			let adIcon = adIconsList[i];
			adIcons[adIcon] = {
				small: createIcon(adIcon, 0.75),
				medium: createIcon(adIcon, 1),
				large: createIcon(adIcon, 1.25)
			};
		}

		/*
		 * Utility functions for popup content creation
		 */
		let tagToImg = function(key, value, validValues, parentNode) {
			if (value == undefined) {
				return false;
			}
			let vals = value.split(';');
			for(let i=0; i < vals.length; i++) {
				let val = vals[i];
				if(validValues.indexOf(val) >= 0) {
					let img = document.createElement("img");
					img.src = "img/"+key+"_"+val+".png";
					img.title = key+" = "+val;
					parentNode.appendChild(img);
				}
			}
			return true;
		};

		let directionTagToImg = function(value, parentNode) {
			if(value !== undefined) {
				let directions = {
					"N": 0, "north": 0, "NNE": 22, "NE": 45, "ENE": 67, "E": 90, "east": 90, "ESE": 112, "SE": 135, "SSE": 157, "S": 180, "south": 180,
					"SSW": 202, "SW": 225, "WSW": 247, "W": 270, "west": 270, "WNW": 292, "NW": 315, "NNW": 337
				};

				//Check if value is valid
				if(!isNaN(parseInt(value)) || directions[value] !== undefined) {
					let valInt = parseInt(value);

					//Convert text value into int
					if(isNaN(valInt)) {
						valInt = directions[value];
					}

					if(valInt !== undefined && valInt >= 0 && valInt < 360) {
						let compass = document.createElement("img");
						compass.src = "img/compass.png";
						compass.title = "direction = "+value;
						compass.style.transform = "rotate("+valInt+"deg)";
						parentNode.appendChild(compass);
						return true;
					}
				}
			}
		};

		let tagsToInfo = function(tags, bindings, parentNode) {
			let hasInfo = false;
			for(let i in bindings) {
				if(tags[i] !== undefined) {
					hasInfo = true;
					break;
				}
			}

			if(hasInfo) {
				let table = document.createElement("table");
				table.className = "feature-info";

				for(let b in bindings) {
					if(tags[b] !== undefined) {
						let row = table.insertRow(0);
						let cell1 = row.insertCell(0);
						cell1.appendChild(document.createTextNode(bindings[b]));
						cell1.className = "label";

						let cell2 = row.insertCell(1);
						cell2.appendChild(document.createTextNode(tags[b]));
						cell2.className = "value";
					}
				}

				parentNode.appendChild(table);
				return true;
			}
		};

		/*
		 * Overpass layer
		 */
		let that = this;
		this.mapData = new L.OverPassLayer({
			query: "(node({{bbox}})[advertising];way({{bbox}})[advertising];);out qt center;",
			onSuccess: function(data) {
				let adValsIgnored = [ "no" ];
				this._markersLayers = {
					small: L.layerGroup(),
					medium: L.layerGroup(),
					large: L.layerGroup()
				};

				for(let i = 0; i < data.elements.length; i++) {
					let e = data.elements[i];

// 					if(e.type == "node" && e.id == 4520653299) {
// 						console.log(e);
// 					}

					if(this._ids[e.type+e.id] || !e.tags.advertising || adValsIgnored.indexOf(e.tags.advertising) >= 0) { continue; }

					this._ids[e.type+e.id] = true;
					let advertisingVal = (adIcons[e.tags.advertising]) ? e.tags.advertising : "unknown";

					//Special case: advert in bus shelter
					if(e.tags.highway == "bus_stop" || e.tags.public_transport == "platform" || e.tags.support == "street_furniture:transit_shelter" || e.tags.shelter == "yes") {
						advertisingVal = "bus_shelter";
					}
					//Special case: political board
					else if(e.tags.advertising == "board" && e.tags.message == "political") {
						advertisingVal = "board_political";
					}
					//Special case: public boards
					else if(e.tags.access === "yes" || e.tags.access === "public") {
						advertisingVal = "board_public";
					}
					//Handle legacy values
					else if(e.tags.message !== undefined && e.tags.message.indexOf("opinions") != -1) {
						// Still lots of message=opinions or opinions;non_profit
						// Cf. https://taginfo.openstreetmap.org/keys/message#values
						e.tags.message = e.tags.message.replace(/opinions/, "opinion")
					}

					//Create popup content
					let popupContent = document.createElement("div"); //this._getPoiPopupHTML(e.tags, e.id);
					let title = document.createElement("h3");
					title.appendChild(document.createTextNode(advertisingVal.replace("_", " ").capitalizeFirstLetter()));
					popupContent.appendChild(title);

					let details = document.createElement("p");
					let detailsAdded = false;
					details.className = "oam-feature-details";
					detailsAdded = tagToImg("support", e.tags.support, ["ceiling", "column", "ground", "pole", "poster_box", "wall_mounted"], details) || detailsAdded;
					detailsAdded = directionTagToImg(e.tags.direction, details) || detailsAdded;
					detailsAdded = tagToImg("animated", e.tags.animated, ["digital_messages", "digital_prices", "revolving", "screen", "trivision_blades", "winding_posters"], details) || detailsAdded;
					detailsAdded = tagToImg("message", e.tags.message, ["commercial", "legal", "local", "non_profit", "opinion", "political", "safety"], details) || detailsAdded;

					let infoTableAdded = tagsToInfo(e.tags, {"sides": "# of sides", "faces": "# of sides", "operator": "Operator", "size": "Size", "permanent": "Permanent"}, details);

					if(!infoTableAdded && detailsAdded) {
						details.appendChild(document.createElement("br"));
					}

					let linkId = document.createElement('a');
					linkId.href = 'http://www.openstreetmap.org/edit?editor=id&'+e.type+'=' + e.id;
					linkId.appendChild(document.createTextNode('Edit in iD'));
					details.appendChild(linkId);
					details.appendChild(document.createTextNode(" or "));
					let linkJosm = document.createElement('a');
					linkJosm.href = '#';
					linkJosm.onclick = () => {
						let request = new XMLHttpRequest();
						request.open('GET', 'http://127.0.0.1:8111/load_object?objects=' + e.type.substring(0,1) + e.id, true);
						request.send();
					};
					linkJosm.appendChild(document.createTextNode('in JOSM'));
					details.appendChild(linkJosm);

					popupContent.appendChild(details);

					//Define popup itself
					let popupOptions = (infoTableAdded) ? { minWidth: 250 } : {};
					let popup = L.popup(popupOptions).setContent(popupContent);

					//Create markers for each size
					let coords = (e.type === 'node') ? L.latLng(e.lat, e.lon) : L.latLng(e.center.lat, e.center.lon);
					this._markersLayers.small.addLayer(L.marker(coords, { icon: adIcons[advertisingVal].small }).bindPopup(popup));
					this._markersLayers.medium.addLayer(L.marker(coords, { icon: adIcons[advertisingVal].medium }).bindPopup(popup));
					this._markersLayers.large.addLayer(L.marker(coords, { icon: adIcons[advertisingVal].large }).bindPopup(popup));
				}

				//Show layer group with correct icon size
				this._map.addLayer(this._markersLayers[that.zoomIconSize[that.lastZoom]]);
			},
			minZoomIndicatorOptions: {
				minZoomMessage: "Zoom in to see data"
			}
		}).addTo(this.map);

		//Handle zoom change for icon resizing
		this.map.on("zoomend", () => {
			let currentZoom = this.map.getZoom();

			if(this.mapData._markersLayers) {
				//Remove previous layer if any
				if(
					this.lastZoom >= 15
					&& this.zoomIconSize[this.lastZoom] != undefined
					&& this.mapData._markersLayers[this.zoomIconSize[this.lastZoom]]
					&& this.map.hasLayer(this.mapData._markersLayers[this.zoomIconSize[this.lastZoom]])
					&& (currentZoom < 15 || this.zoomIconSize[currentZoom] != this.zoomIconSize[this.lastZoom])
				) {
					this.map.removeLayer(this.mapData._markersLayers[this.zoomIconSize[this.lastZoom]]);
				}

				//Add layer if needed
				if(
					this.zoomIconSize[currentZoom] !== undefined
					&& (
						(this.zoomIconSize[this.lastZoom] !== undefined && this.zoomIconSize[currentZoom] != this.zoomIconSize[this.lastZoom])
						|| this.lastZoom < 15
					)
					&& this.mapData._markersLayers[this.zoomIconSize[currentZoom]]
				) {
					this.map.addLayer(this.mapData._markersLayers[this.zoomIconSize[currentZoom]]);
				}
			}

			this.lastZoom = currentZoom;
		});
	}
}

module.exports = {
	init: function(divId) {
		return new Main(divId);
	}
};
